// Инициализируем два экземпляра fslightbox для двух галерей
const fslightbox1 = new FsLightbox();
const fslightbox2 = new FsLightbox();

// Обработчик для первой кнопки (показывает 1-6 фото)
document
  .getElementById("show-gallery-1")
  .addEventListener("click", function () {
    fslightbox1.props.sources = [
      "img/gallery-1.jpg",
      "img/gallery-2.jpg",
      "img/gallery-3.jpg",
      "img/gallery-4.jpg",
      "img/gallery-5.jpg",
      "img/gallery-6.jpg",
    ];
    fslightbox1.open();
  });

// Обработчик для второй кнопки (показывает 7-12 фото)
document
  .getElementById("show-gallery-2")
  .addEventListener("click", function () {
    fslightbox2.props.sources = [
      "img/gallery-x-1.jpg",
      "img/gallery-x-7.jpg",
      "img/gallery-block-3.jpg",
      "img/gallery-x-5.jpg",
      "img/gallery-x-9.jpg",
      "img/gallery-x-10.jpg",
    ];
    fslightbox2.open();
  });

// Запрещаем действие по умолчанию для всех изображений
document.querySelectorAll(".gallery__items a").forEach(function (imageLink) {
  imageLink.addEventListener("click", function (event) {
    event.preventDefault(); // Отменяем действие по умолчанию
  });
});
